<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="12" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: type === 'switch' ? 'query' : '', value: '', isChanged: false, isEncrypt: false, type: 'PLAIN' })"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" class="pt-2">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col v-if="userSettings.display.showId" cols="1">
            <span>{{ $lang.header.id }}</span>
          </v-col>
          <v-col cols="5">
            <span>{{ $lang.header.key }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.value }}</span>
          </v-col>
          <v-col cols="1" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="center" class="pb-1">
            <v-col v-if="userSettings.display.showId" cols="1">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="5">
              <v-text-field
                v-model="item.text"
                dense
                :readonly="!canEdit"
                required
                hide-details
                :rules="[v => !!v || $lang.labels.required]"
                :data-cy="`${type}-key-${i}`"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="px-1">
              <div class="d-inline-flex max-width align-center">
                <v-text-field
                  v-model="item.value"
                  dense
                  required
                  hide-details
                  :rules="[v => !!v || $lang.labels.required]"
                  :readonly="!canEdit || item.isEncrypt"
                  :data-cy="`${type}-value-${i}`"
                  :type="item.isEncrypt ? 'password' : 'text'"
                  @change="item.isChanged = true"
                ></v-text-field>
                <v-checkbox
                  v-model="item.isEncrypt"
                  class="ml-2"
                  hide-details
                  dense
                  :label="$lang.labels.encrypt"
                  @change="item.isChanged = true; !item.isEncrypt ? (item.value = '', item.type = 'PLAIN') : item.type = 'ENCRYPT'"
                ></v-checkbox>
              </div>
            </v-col>
            <v-col cols="1" class="text-right">
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    encryptionParams: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data: () => ({
    loading: true,
    deconstructedData: [],
    duplicatedKeys: false,
    encrypt: null
  }),
  computed: {
    ...mapState('app', ['userSettings'])
  },
  watch: {
    deconstructedData: {
      handler() {
        const tempData = {}
        const tempEncryptData = []

        this.deconstructedData.forEach((item) => {
          if (item.text && item.value) {
            tempData[item.text] = item.value

            tempEncryptData.push({
              field: item.text,
              type: item.isEncrypt ? 'ENCRYPT' : 'PLAIN',
              isEncrypt: item.isEncrypt,
              isChanged: item.isChanged
            })
          }
        })
        if (this.type === 'jwt' && this.encryptionParams.find((x) => x.field === 'privateKey')) {
          const tempEncryptPrivateKey = this.encryptionParams.find((x) => x.field === 'privateKey')

          tempEncryptData.push({
            field: 'privateKey',
            type: tempEncryptPrivateKey.isEncrypt ? 'ENCRYPT' : 'PLAIN',
            isEncrypt: tempEncryptPrivateKey.isEncrypt,
            isChanged: tempEncryptPrivateKey.isChanged
          })
        }
        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        if (!this.loading) {
          this.$emit('dataChanged', tempData)
          this.$emit('dataEncryptChanged', tempEncryptData)
        }
      },
      deep: true
    }
  },
  created() {
    if (this.data) {
      for (const row in this.data) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.data.hasOwnProperty(row)) {
          const tempEncrypt = this.encryptionParams.find((x) => x.field === row)

          if (tempEncrypt) {
            this.deconstructedData.push({
              text: row,
              value: this.data[row],
              type: tempEncrypt.type,
              isEncrypt: tempEncrypt.type !== 'PLAIN',
              isChanged: false
            })
          } else {
            this.deconstructedData.push({
              text: row,
              value: this.data[row],
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            })
          }
        }
      }
    }
    setTimeout(() => {
      this.loading = false
    }, 100)
  },
  methods: {
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
