export const stepExceptions = {
  CSV: ['IOException', 'InvalidCsvSourceStepException'],
  EXECUTE_EXTERNAL_COMMAND: ['IOException'],
  EXECUTE_PROCESS: ['ChildProcessNotFoundException', 'ChildEventOfParentProcessNotFoundException', 'ChildProcessTimeoutException', 'ProcessDisabledException'],
  FOREACH: [],
  GROOVY: ['MultipleCompilationErrorsException', 'CompilationFailedException', 'MissingPropertyException', 'MissingClassException', 'MissingFieldException', 'MissingMethodException', 'GroovyRuntimeException', 'StringWriterIOException'],
  IMAGE: ['IOException', 'MalformedURLException', 'XRRuntimeException'],
  IMAP: ['NoSuchProviderException', 'MessagingException', 'ImapMessageNotFoundException'],
  JDBC: ['SQLException', 'NotSingleResultException'],
  JS: ['PolyglotException', 'LibraryDisabledException', 'MissedLibraryArtifactException', 'InvalidLibraryTypeException'],
  JWT: ['NoSuchAlgorithmException', 'InvalidKeySpecException', 'InvalidKeyException'],
  MESSAGING: [],
  MONGODB: ['MongoChangeStreamException', 'MongoClientException', 'MongoConfigurationException', 'MongoSecurityException', 'MongoTimeoutException', 'MongoWaitQueueFullException', 'MongoExecutionTimeoutException',
    'MongoGridFSException', 'MongoIncompatibleDriverException', 'MongoInternalException', 'MongoInterruptedException', 'MongoServerException', 'BulkWriteException', 'MongoBulkWriteException', 'MongoCommandException',
    'MongoNodeIsRecoveringException', 'MongoNotPrimaryException', 'MongoQueryException', 'MongoCursorNotFoundException', 'MongoWriteConcernException', 'MongoWriteException', 'WriteConcernException', 'DuplicateKeyException',
    'MongoSocketException', 'MongoSocketClosedException', 'MongoSocketOpenException', 'MongoSocketReadException', 'MongoSocketReadTimeoutException', 'MongoSocketWriteException', 'NotSingleResultException'],
  PDF: ['IOException', 'DocumentException'],
  PLUGIN: ['PluginDisabledException', 'PluginNotFoundException', 'PluginSettingDisabledException', 'PluginSettingNotFoundException', 'BundleException', 'ClassNotFoundException', 'NoSuchMethodException', 'InvocationTargetException'],
  PROCESS_SETTING: ['ProcessSettingsDisabledException', 'ProcessSettingsNotFoundException'],
  REST: ['JsonProcessingException', 'IllegalArgumentException'],
  S3: ['S3BucketRequiredException', 'JsonProcessingException', 'IOException', 'NoSuchKeyException', 'InvalidObjectStateException', 'SdkException', 'SdkClientException', 'S3Exception', 'NoSuchBucketException'],
  SECURITY: ['JsonProcessingException', 'JsonMappingException', 'NoSuchAlgorithmException', 'InvalidKeySpecException', 'NoSuchPaddingException', 'InvalidKeyException', 'InvalidAlgorithmParameterException', 'IllegalBlockSizeException', 'BadPaddingException'],
  SLACK: ['IOException', 'SlackApiException'],
  SMTP: ['JsonProcessingException', 'MessagingException'],
  EMAIL: ['JsonProcessingException', 'MessagingException'],
  SWITCH: [],
  TRY_CATCH: ['RestClientException'],
  TWILIO: [],
  UNSET_VARIABLES: [],
  USER: ['InvalidUserIdException'],
  WHILE: [],
  PAYMENT_SENSE_CONNECT_E: ['RestClientException'],
  PAYMENT_SENSE_PAC: ['RestClientException'],
  FORMATTING: ['IllegalArgumentException'],
  PYTHON: ['JepException'],
  XSLT: ['XsltProcessStepsException'],
  GRAPHQL: ['JsonProcessingException', 'IllegalArgumentException'],
  ENCODER: ['IllegalArgumentException'],
  QR: ['QRDataTooBigException']
}

export const localesFormatting = [
  { text: 'Albania (sq_AL)', value: 'sq_AL' },
  { text: 'Algeria (ar_DZ)', value: 'ar_DZ' },
  { text: 'Bahrain (ar_BH)', value: 'ar_BH' },
  { text: 'Egypt (ar_EG)', value: 'ar_EG' },
  { text: 'Iraq (ar_IQ)', value: 'ar_IQ' },
  { text: 'Jordan (ar_JO)', value: 'ar_JO' },
  { text: 'Kuwait (ar_KW)', value: 'ar_KW' },
  { text: 'Lebanon (ar_LB)', value: 'ar_LB' },
  { text: 'Libya (ar_LY)', value: 'ar_LY' },
  { text: 'Morocco (ar_MA)', value: 'ar_MA' },
  { text: 'Oman (ar_OM)', value: 'ar_OM' },
  { text: 'Qatar (ar_QA)', value: 'ar_QA' },
  { text: 'Saudi Arabia (ar_SA)', value: 'ar_SA' },
  { text: 'Sudan (ar_SD)', value: 'ar_SD' },
  { text: 'Syria (ar_SY)', value: 'ar_SY' },
  { text: 'Tunisia (ar_TN)', value: 'ar_TN' },
  { text: 'United Arab Emirates (ar_AE)', value: 'ar_AE' },
  { text: 'Yemen (ar_YE)', value: 'ar_YE' },
  { text: 'Belarus (be_BY)', value: 'be_BY' },
  { text: 'Bulgaria (bg_BG)', value: 'bg_BG' },
  { text: 'Spain (ca_ES)', value: 'ca_ES' },
  { text: 'China (zh_CN)', value: 'zh_CN' },
  { text: 'Singapore (zh_SG)', value: 'zh_SG' },
  { text: 'Hong Kong (zh_HK)', value: 'zh_HK' },
  { text: 'Taiwan (zh_TW)', value: 'zh_TW' },
  { text: 'Croatia (hr_HR)', value: 'hr_HR' },
  { text: 'Czech Republic (cs_CZ)', value: 'cs_CZ' },
  { text: 'Denmark (da_DK)', value: 'da_DK' },
  { text: 'Belgium (nl_BE)', value: 'nl_BE' },
  { text: 'Netherlands (nl_NL)', value: 'nl_NL' },
  { text: 'Australia (en_AU)', value: 'en_AU' },
  { text: 'Canada (en_CA)', value: 'en_CA' },
  { text: 'India (en_IN)', value: 'en_IN' },
  { text: 'Ireland (en_IE)', value: 'en_IE' },
  { text: 'Malta (en_MT)', value: 'en_MT' },
  { text: 'New Zealand (en_NZ)', value: 'en_NZ' },
  { text: 'Philippines (en_PH)', value: 'en_PH' },
  { text: 'Singapore (en_SG)', value: 'en_SG' },
  { text: 'South Africa (en_ZA)', value: 'en_ZA' },
  { text: 'United Kingdom (en_GB)', value: 'en_GB' },
  { text: 'United States (en_US)', value: 'en_US' },
  { text: 'Estonia (et_EE)', value: 'et_EE' },
  { text: 'Finland (fi_FI)', value: 'fi_FI' },
  { text: 'Belgium (fr_BE)', value: 'fr_BE' },
  { text: 'Canada (fr_CA)', value: 'fr_CA' },
  { text: 'France (fr_FR)', value: 'fr_FR' },
  { text: 'Luxembourg (fr_LU)', value: 'fr_LU' },
  { text: 'Switzerland (fr_CH)', value: 'fr_CH' },
  { text: 'Austria (de_AT)', value: 'de_AT' },
  { text: 'Germany (de_DE)', value: 'de_DE' },
  { text: 'Luxembourg (de_LU)', value: 'de_LU' },
  { text: 'Switzerland (de_CH)', value: 'de_CH' },
  { text: 'Cyprus (el_CY)', value: 'el_CY' },
  { text: 'Greece (el_GR)', value: 'el_GR' },
  { text: 'Israel (iw_IL)', value: 'iw_IL' },
  { text: 'India (hi_IN)', value: 'hi_IN' },
  { text: 'Hungary (hu_HU)', value: 'hu_HU' },
  { text: 'Iceland (is_IS)', value: 'is_IS' },
  { text: 'Indonesia (in_ID)', value: 'in_ID' },
  { text: 'Ireland (ga_IE)', value: 'ga_IE' },
  { text: 'Italy (it_IT)', value: 'it_IT' },
  { text: 'Switzerland (it_CH)', value: 'it_CH' },
  { text: 'Japan (ja_JP)', value: 'ja_JP' },
  { text: 'Japan (ja_JP_u_ca_japanese)', value: 'ja_JP_u_ca_japanese' },
  { text: 'Japan (ja_JP_x_lvariant_JP)', value: 'ja_JP_x_lvariant_JP' },
  { text: 'South Korea (ko_KR)', value: 'ko_KR' },
  { text: 'Latvia (lv_LV)', value: 'lv_LV' },
  { text: 'Lithuania (lt_LT)', value: 'lt_LT' },
  { text: 'Macedonia (mk_MK)', value: 'mk_MK' },
  { text: 'Malaysia (ms_MY)', value: 'ms_MY' },
  { text: 'Malta (mt_MT)', value: 'mt_MT' },
  { text: 'Norway (no_NO)', value: 'no_NO' },
  { text: 'Norway (nb_NO)', value: 'nb_NO' },
  { text: 'Norway (nn_NO)', value: 'nn_NO' },
  { text: 'Norway (no_NO_x_lvariant_NY)', value: 'no_NO_x_lvariant_NY' },
  { text: 'Poland (pl_PL)', value: 'pl_PL' },
  { text: 'Brazil (pt_BR)', value: 'pt_BR' },
  { text: 'Portugal (pt_PT)', value: 'pt_PT' },
  { text: 'Romania (ro_RO)', value: 'ro_RO' },
  { text: 'Russia (ru_RU)', value: 'ru_RU' },
  { text: 'Bosnia and Herzegovina (sr_BA)', value: 'sr_BA' },
  { text: 'Montenegro (sr_ME)', value: 'sr_ME' },
  { text: 'Serbia (sr_RS)', value: 'sr_RS' },
  { text: 'Bosnia and Herzegovina (sr_Latn_BA)', value: 'sr_Latn_BA' },
  { text: 'Montenegro (sr_Latn_ME)', value: 'sr_Latn_ME' },
  { text: 'Serbia (sr_Latn_RS)', value: 'sr_Latn_RS' },
  { text: 'Slovakia (sk_SK)', value: 'sk_SK' },
  { text: 'Slovenia (sl_SI)', value: 'sl_SI' },
  { text: 'Argentina (es_AR)', value: 'es_AR' },
  { text: 'Bolivia (es_BO)', value: 'es_BO' },
  { text: 'Chile (es_CL)', value: 'es_CL' },
  { text: 'Colombia (es_CO)', value: 'es_CO' },
  { text: 'Costa Rica (es_CR)', value: 'es_CR' },
  { text: 'Dominican Republic (es_DO)', value: 'es_DO' },
  { text: 'Ecuador (es_EC)', value: 'es_EC' },
  { text: 'El Salvador (es_SV)', value: 'es_SV' },
  { text: 'Guatemala (es_GT)', value: 'es_GT' },
  { text: 'Honduras (es_HN)', value: 'es_HN' },
  { text: 'Mexico (es_MX)', value: 'es_MX' },
  { text: 'Nicaragua (es_NI)', value: 'es_NI' },
  { text: 'Panama (es_PA)', value: 'es_PA' },
  { text: 'Paraguay (es_PY)', value: 'es_PY' },
  { text: 'Peru (es_PE)', value: 'es_PE' },
  { text: 'Puerto Rico (es_PR)', value: 'es_PR' },
  { text: 'Spain (es_ES)', value: 'es_ES' },
  { text: 'United States (es_US)', value: 'es_US' },
  { text: 'Uruguay (es_UY)', value: 'es_UY' },
  { text: 'Venezuela (es_VE)', value: 'es_VE' },
  { text: 'Sweden (sv_SE)', value: 'sv_SE' },
  { text: 'Thailand (th_TH)', value: 'th_TH' },
  { text: 'Thailand (th_TH_u_ca_buddhist)', value: 'th_TH_u_ca_buddhist' },
  { text: 'Thailand (th_TH_u_ca_buddhist_nu_thai)', value: 'th_TH_u_ca_buddhist_nu_thai' },
  { text: 'Thailand (th_TH_x_lvariant_TH)', value: 'th_TH_x_lvariant_TH' },
  { text: 'Turkey (tr_TR)', value: 'tr_TR' },
  { text: 'Ukraine (uk_UA)', value: 'uk_UA' },
  { text: 'Vietnam (vi_VN)', value: 'vi_VN' }
]

export const regions = [
  'af-south-1',
  'ap-east-1',
  'ap-northeast-1',
  'ap-northeast-2',
  'ap-northeast-3',
  'ap-south-1',
  'ap-south-2',
  'ap-southeast-1',
  'ap-southeast-2',
  'ap-southeast-3',
  'ap-southeast-4',
  'ca-central-1',
  'eu-central-1',
  'eu-central-2',
  'eu-north-1',
  'eu-south-1',
  'eu-south-2',
  'eu-west-1',
  'eu-west-2',
  'eu-west-3',
  'il-central-1',
  'me-central-1',
  'me-south-1',
  'sa-east-1',
  'us-east-1',
  'us-east-2',
  'us-west-1',
  'us-west-2'
]

export const defaultRestVariables = [
  { key: '$.restRequest.url' },
  { key: '$.restRequest.method' },
  { key: '$.restRequest.queryParameters' },
  { key: '$.restRequest.requestBody' },
  { key: '$.restRequest.headers' },
  { key: '$.restRequest.pathParameters' },
  { key: '$.restResponse.body' },
  { key: '$.restResponse.statusCode' },
  { key: '$.restResponse.fullBody' }
]

export const MAX_KB_CACHE_TO_DISPLAY = 512

export const SUPPORTED_LANGUAGES = ['en', 'de', 'bg', 'zh', 'hr', 'cs', 'da', 'nl', 'et', 'fi', 'fr', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk']

export const SUPPORTED_RECURRING_TYPES = ['\'SECONDLY\'', '\'MINUTELY\'', '\'HOURLY\'', '\'DAILY\'', '\'WEEKLY\'', '\'MONTHLY\'', '\'YEARLY\'']

export const WEEK_DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
