<template>
  <v-container fluid class="custom-container-details">
    <div>
      <v-row no-gutters class="fill-height full-width" >
        <v-col
          cols="12"
        >
          <v-alert v-if="!userCanEdit && !loading" dense color="warning" style="color: black">
            {{ $lang.errors.readOnly }}
          </v-alert>
          <v-alert v-if="showCorruptedMessage && !loading" dense color="warning" style="color: black">
            {{ $lang.errors.credentialCorruptedMessage }}
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          class="fill-height"
        >
          <v-tabs
            v-model="tab"
            class="custom-tab-design"
            background-color="transparent"
          >

            <v-tab
              :key="0"
              data-cy="tab-details"
              class="ml-2"
            >
              {{ $lang.labels.details }}
            </v-tab>
            <v-tab
              v-if="userCanEdit && isEdit && queryConsoleFromBe && credential.adminOptions.queryConsole"
              :key="1"
              data-cy="tab-console"
              class="ml-2"
            >
              {{ $lang.labels.console }}
            </v-tab>
            <v-tab
              :key="2"
              data-cy="tab-permissions"
              class="ml-2"
            >
              {{ $lang.labels.permissions }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class=" custom-bg pt-6">
            <v-tab-item
              :key="0"
              class="fill-height "
            >
              <v-card class="pa-1 fill-height custom-bg" >
                <v-form
                  ref="form"
                  v-model="valid"
                  class="pt-1"
                >
                  <v-row wrap no-gutters justify="space-between" class="pb-1">
                    <v-col
                      cols="12"
                      sm="9"
                      class="pr-sm-1 d-flex"
                    >
                      <v-text-field
                        v-model="credential.name"
                        outlined
                        dense
                        color="primary copy-name-icon"
                        :label="$lang.labels.name"
                        required
                        :rules="[v => !!v || $lang.labels.required, v => (v && v.length > 1 && v.length <= 250) || $lang.errors.nameTooShortOrLong]"
                        :persistent-hint="isEdit"
                        :hint="formatHint"
                        :readonly="!userCanEdit || credential.isSystem"
                        data-cy="credential-name"
                        append-icon="mdi-vector-combine"
                        class="required-asterisk py-2"
                        @click:append="copyField()"
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="isEdit" cols="3" class="pl-1 text-right">
                      <v-btn
                        outlined
                        class=" mt-2 pl-2"
                        color="primary"
                        :disabled="!userCanEdit"
                        @click="changeStatus()"
                      >
                        <v-icon
                          left
                          dark
                        >
                          {{ credential.status === 'ACTIVE' ? 'mdi-toggle-switch-off ' : 'mdi-toggle-switch' }}
                        </v-icon>
                        {{ credential.status === 'ACTIVE' ? $lang.actions.deactivate : $lang.actions.activate }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row v-if="!loading && credential.encryptedFields" wrap no-gutters>
                    <v-col cols="12" class="pb-2">
                      <h4 class="text-h5">{{ $lang.header.values }} - {{ $lang.status[credential.type] }}</h4>
                    </v-col>
                    <values-component
                      :can-edit="userCanEdit"
                      :is-edit="isEdit"
                      :is-copy="isCopy"
                      :type="credential.type"
                      :default-values="credential.values"
                      :encryption-fields="credential.encryptedFields"
                      :loading="loading"
                      @setValues="credential.values = $event"
                      @setValid="valuesValid = $event"
                      @setEncryptedFields="credential.encryptedFields = $event"
                    ></values-component>
                    <v-col
                      cols="12"
                      class="py-2"
                    >
                      <hr style="border-bottom-color: var(--v-primary-base)">
                    </v-col>
                    <v-col
                      cols="12"
                      class="pb-4"
                    >
                      <v-row wrap no-gutters align="center">
                        <v-checkbox
                          v-model="credential.isGdprRelevant"
                          :label="$lang.labels.gdpr"
                          hide-details
                          class="mt-0"
                        ></v-checkbox>
                        <v-checkbox
                          v-if="$route.params.type ? $route.params.type.toUpperCase() === 'JDBC' : credential && credential.type && credential.type.toUpperCase() === 'JDBC'"
                          v-model="credential.adminOptions.queryConsole"
                          hide-details
                          class="align-center align-self-center ml-3 mt-0"
                          :label="$lang.labels.allowQueryConsole"
                          :readonly="!userCanEdit || credential.isSystem"
                        ></v-checkbox>
                        <span
                          v-if="credential.adminOptions.queryConsole && !queryConsoleFromBe"
                          class="ml-1 color-primary"
                        >( {{ $lang.hints.saveFirst }} )</span>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between">
                    <v-divider class="mb-4" />
                  </v-row>
                  <v-row v-if="testResult" dense no-gutters class="px-0">
                    <v-col v-if="testResult" cols="12">
                      <v-alert :color="resultColor">{{ testResult }}</v-alert>
                    </v-col>
                  </v-row>
                  <v-row no-gutters wrap justify="space-between" >
                    <v-col
                      cols="6"
                      class="text-left"
                    >
                      <div class="d-inline-flex align-center">
                        <v-btn
                          outlined
                          color="primary"
                          :to="{ name: 'credentials', params: { lang: $lang.current_lang } }"
                        >
                          <v-icon
                            left
                            dark
                          >
                            mdi-arrow-left
                          </v-icon>
                          <span class="ml-1">{{ $lang.actions.back }}</span>
                        </v-btn>
                        <action-button-with-confirmation
                          v-if="isEdit"
                          outlined
                          :action-text="$lang.actions.areYouSureYouWantToDelete"
                          :action-text-suffix="credential.name"
                          :title="$lang.actions.delete"
                          type="credential"
                          class="ml-2"
                          :is-disabled="!userCanDelete"
                          :button-text="$lang.actions.delete"
                          :button-color="'error'"
                          :data-cy="'credential-delete'"
                          :forced-option="true"
                          :trigger-force-logic="triggerForceLogic"
                          :regular-delete-errors-usages="regularDeleteErrorsUsages"
                          :delete-success="deleteSuccess"
                          @closeAfterDelete="$router.push({ name: 'credentials' })"
                          @submit="deleteCredentialFunct($event)"
                          @closeDialog="''"
                        />
                      </div>
                    </v-col>
                    <v-col
                      cols="6"
                      class="text-right"
                    >
                      <v-progress-circular
                        v-if="lock || loading"
                        indeterminate
                        :color="loading ? 'success' : 'accent'"
                      ></v-progress-circular>
                      <v-btn
                        outlined
                        :disabled="!isFormValid || ['REST', 'PLUGIN', 'SECURITY', 'SCRIPT'].includes(credential.type)"
                        color="primary"
                        class="button-default-width ml-1 color-accent-text"
                        @click="test()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi-test-tube
                        </v-icon>
                        {{ $lang.actions.test }}
                      </v-btn>
                      <v-btn
                        outlined
                        :disabled="!isFormValid || !isEdit"
                        color="primary"
                        class="ml-2  color-accent-text"
                        @click="copyCreateResource(credential, 'credentialCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                      >
                        <v-icon
                          left
                          dark
                        >
                          mdi-content-copy
                        </v-icon>
                        {{ $lang.actions.copyCreate }}
                      </v-btn>
                      <v-btn
                        :disabled="!isFormValid"
                        color="primary"
                        class="button-default-width ml-2"
                        @click="submit()"
                      >
                        <v-icon
                          left
                          dark
                          class="mr-1"
                        >
                          mdi mdi-floppy
                        </v-icon>
                        {{ $lang.actions.submit }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-tab-item>
            <v-tab-item
              v-if="userCanEdit && isEdit && queryConsoleFromBe && credential.adminOptions.queryConsole"
              :key="1"
              class="fill-height"
            >
              <v-card v-if="!loading" class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div style="width: 100%; height: 100%">
                      <editor
                        :title="$lang.labels.queryConsole"
                        :item="script"
                        @fromGlobalEditor="script = $event"
                      ></editor>
                    </div>
                  </v-col>
                  <v-col cols="12" class="pb-2 px-2 text-right">
                    <v-progress-circular
                      v-if="executingQuery"
                      indeterminate
                      color="accent"
                    ></v-progress-circular>
                    <v-btn
                      :disabled="!script || executingQuery"
                      color="accent"
                      class="button-default-width ml-1 color-accent-text"
                      @click="executeJdbcScriptFunc()"
                    >
                      {{ $lang.actions.execute }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-divider class="py-1"></v-divider>
                <v-row v-if="showExecuteSuccessResult" wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <v-card class="pa-2" flat>
                      <v-card-title class="pb-2 d-flex flex-grow-1 align-center">
                        <div>{{ $lang.header.result }}</div>
                        <v-spacer></v-spacer>
                        <div class="d-flex">
                          <v-card-title>{{ $lang.labels.affectedRows }}: <span
                            class="color-primary ml-1"
                          >{{ executeSuccessResult.affectedRows }}</span></v-card-title>
                          <v-card-title class="ml-2">{{ $lang.labels.success }}: <span class="color-primary ml-1">{{
                            executeSuccessResult.isSuccess ? $lang.labels.yes : $lang.labels.no
                          }}</span></v-card-title>
                        </div>
                      </v-card-title>
                      <v-divider class="py-1"></v-divider>
                      <v-card-subtitle class="pb-2">{{ $lang.labels.resultDetails }}:</v-card-subtitle>
                      <div v-if="executeSuccessResult.isSuccess">
                        <v-data-table
                          :headers="dynamicTableHead"
                          :items="executeSuccessResult.result || []"
                          class="elevation-0"
                          :footer-props="{
                            'items-per-page-options': rowsPerPageItemsGlobal
                          }"
                        >
                          <template v-slot:item="{ item, index }">
                            <tr :key="index">
                              <td v-for="header in dynamicTableHead" :key="header.value">
                                <div v-if="isJSON(item[header.value])">
                                  <a href="#" style="text-decoration: none" @click.prevent="openFullJSONDialog(item[header.value], header.value)">
                                    {{ truncateString(item[header.value]) }}
                                  </a>
                                </div>
                                <div v-else>
                                  {{ item[header.value] }}
                                </div>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </div>
                      <div v-else>
                        <v-row wrap no-gutters class="pt-2">
                          <v-col cols="12">
                            <v-alert color="error">{{ executeSuccessResult.error }}</v-alert>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="pb-2 px-2 text-right">
                    <v-btn
                      color="accent"
                      class="button-default-width ml-1 color-accent-text"
                      @click="resetExecuteJdbcScript()"
                    >
                      {{ $lang.actions.reset }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
            <v-tab-item
              :key="2"
              class="fill-height"
            >
              <v-card class="pa-2 fill-height custom-bg">
                <v-row wrap no-gutters class="pb-4">
                  <v-col cols="12" class="pb-2">
                    <div v-if="allRoles && allRoles.length > 0" style="width: 100%; height: 100%">
                      <h3 class="pb-1">{{ $lang.status.EDIT }}</h3>
                      <user-roles-select
                        :role="editRolesIds"
                        :options="allRoles"
                        data-cy="roles-edit"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="editRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.USE }}</h3>
                      <user-roles-select
                        :role="useRolesIds"
                        :options="useRolePool"
                        data-cy="roles-use"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="useRolesIds = $event"
                      ></user-roles-select>
                      <h3 class="pb-1">{{ $lang.status.VIEW }}</h3>
                      <user-roles-select
                        :role="viewRolesIds"
                        :options="viewRolePool"
                        data-cy="roles-view"
                        :required="false"
                        :readonly="!userCanEdit"
                        @changeRoles="viewRolesIds = $event"
                      ></user-roles-select>
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="snackShow"
        :color="snackColor"
        content-class="text-center"
        top
      >
        <span class="color-accent-text">{{ snackbarText }}</span>
      </v-snackbar>
      <multi-errors-snackbar
        :show="showSnackErrors"
        :errors="errorsForSnackbar"
        @close="showSnackErrors = false; errorsForSnackbar = []"
      />
    </div>
    <v-dialog v-model="showJSONDialog" max-width="75%" height="80vh">
      <v-card class="pa-2" flat>
        <p class="pb-0 mb-0">{{ fullJsonLabel }}</p>
        <v-lazy style="width: 100%; height: 63vh; overflow-y: auto" class="py-2">

          <json-custom-viewer :data="fullJSON" @closeDialog="showJSONDialog = false" />
        </v-lazy>

        <div class="d-flex w-100 justify-end mt-1">
          <v-btn color="primary" text @click="showJSONDialog = false">
            {{ $lang.actions.close }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ValuesComponent from './Values'
import {
  disableProcessCredentialUsingGET as disableCredential,
  enableProcessCredentialUsingGET as enableCredential,
  getProcessCredentialByIdUsingGET as getCredential,
  updateTwilioProcessCredentialUsingPUT as updateTwilio,
  createTwilioProcessCredentialUsingPOST as createTwilio,
  updateSmtpProcessCredentialUsingPUT as updateSmtp,
  createSmtpProcessCredentialUsingPOST as createSmtp,
  updateJdbcProcessCredentialUsingPUT as updateJdbc,
  createJdbcProcessCredentialUsingPOST as createJdbc,
  updateMongodbProcessCredentialUsingPUT as updateMongodb,
  createMongodbProcessCredentialUsingPOST as createMongodb,
  updateJwtProcessCredentialUsingPUT as updateJwt,
  createJwtProcessCredentialUsingPOST as createJwt,
  updateSlackProcessCredentialUsingPUT as updateSlack,
  createSlackProcessCredentialUsingPOST as createSlack,
  createRestCredentialUsingPOST as createRest,
  updateRestCredentialUsingPUT as updateRest,
  createPluginCredentialUsingPOST as createPlugin,
  updatePluginCredentialUsingPUT as updatePlugin,
  createFlowyCredentialUsingPOST as createFlowy,
  updateFlowyCredentialUsingPUT as updateFlowy,
  updateAwsCredentialUsingPUT as updateAws,
  createAwsCredentialUsingPOST as createAws,
  updateSecurityCredentialUsingPUT as updateSecurity,
  createSecurityCredentialUsingPOST as createSecurity,
  testJwtProcessCredentialUsingPOST as testJwt,
  testTwilioProcessCredentialUsingPOST as testTwilio,
  testSmtpProcessCredentialUsingPOST as testSmtp,
  testJdbcProcessCredentialUsingPOST as testJdbc,
  testMongodbProcessCredentialUsingPOST as testMongoDb,
  testSlackProcessCredentialUsingPOST as testSlack,
  testFlowyCredentialUsingPOST as testFlowy,
  testAwsCredentialUsingPOST as testAws,
  executeJdbcScriptUsingPOST as executeJdbcScript,
  createScriptCredentialUsingPOST as createScript,
  updateScriptCredentialUsingPUT as updateScript,
  createImapProcessCredentialUsingPOST as createImap,
  updateImapProcessCredentialUsingPUT as updateImap,
  testImapProcessCredentialUsingPOST as testImap,
  createKafkaCredentialUsingPOST as createKafka,
  updateKafkaCredentialUsingPUT as updateKafka,
  testKafkaCredentialUsingPOST as testKafka,
  createPaymentSensePacCredentialUsingPOST as createPaymentSensePac,
  updatePaymentSensePacCredentialUsingPUT as updatePaymentSensePac,
  testPaymentSensePacCredentialUsingPOST as testPaymentSensePac,
  createJmsCredentialUsingPOST as createJms,
  updateJmsCredentialUsingPUT as updateJms,
  testJmsCredentialUsingPOST as testJms,
  createPaymentSenseConnectECredentialUsingPOST as createPaymentSenseConnectE,
  updatePaymentSenseConnectECredentialUsingPUT as updatePaymentSenseConnectE,
  testPaymentSenseConnectECredentialUsingPOST as testPaymentSenseConnectE,
  deleteProcessCredentialUsingDELETE as deleteCredential,
  createRabbitmqCredentialUsingPOST as createRabbitmq,
  updateRabbitmqCredentialUsingPUT as updateRabbitmq,
  testRabbitmqCredentialUsingPOST as testRabbitmq,
  createMqttCredentialUsingPOST as createMqtt,
  updateMqttCredentialUsingPUT as updateMqtt,
  testMqttCredentialUsingPOST as testMqtt,
  createRestBasicCredentialUsingPOST as createRestBasic,
  updateRestBasicCredentialUsingPUT as updateRestBasic,
  updateRestBearerCredentialUsingPUT as updateRestBearer,
  createRestBearerCredentialUsingPOST as createRestBearer,
  createFtpProcessCredentialUsingPOST as createFtp,
  updateFtpProcessCredentialUsingPUT as updateFtp,
  testFtpProcessCredentialUsingPOST as testFtp,
  createSftpProcessCredentialUsingPOST as createSftp,
  updateSftpProcessCredentialUsingPUT as updateSftp,
  testSftpProcessCredentialUsingPOST as testSftp,
  createOpenAIProcessCredentialUsingPOST as createOpenAi,
  updateOpenAIProcessCredentialUsingPUT as updateOpenAi,
  testOpenAICredentialUsingPOST as testOpenAi,
  createLdapCredentialUsingPOST as createLdap,
  updateLdapCredentialUsingPUT as updateLdap,
  testLdapCredentialUsingPOST as testLdap,
  createSshCredentialUsingPOST as createSsh,
  updateSshCredentialUsingPUT as updateSsh,
  testSshCredentialUsingPOST as testSsh,
  createSshKeyCredentialUsingPOST as createSshKey,
  updateSshKeyCredentialUsingPUT as updateSshKey,
  testSshKeyCredentialUsingPOST as testSshKey,
  createOutlookCalendarCredentialUsingPOST as createOutlookCalendar,
  updateOutlookCalendarCredentialUsingPUT as updateOutlookCalendar,
  testOutlookCalendarCredentialUsingPOST as testOutlookCalendar
} from '@/utils/api'
import editor from '../../components/ui/GlobalEditor'
import { mapState } from 'vuex'
import UserRolesSelect from '../../components/ui/UserRolesSelect'
import copy from 'copy-to-clipboard'
import ActionButtonWithConfirmation from '@/components/ui/ActionButtonWithConfirmation.vue'
import MultiErrorsSnackbar from '@/components/ui/MultiErrorsSnackbar.vue'
import { definitions } from '@/utils/definitions'
import JsonCustomViewer from '@/components/ui/JsonCustomViewer.vue'
import { mapActions } from 'vuex'
import { checkSftpValues, copyCreateResource, getRolesWithoutAuth } from '@/utils/helpers'
import { bus } from '@/main'

export default {
  components: {
    JsonCustomViewer,
    ActionButtonWithConfirmation,
    MultiErrorsSnackbar,
    ValuesComponent,
    editor,
    UserRolesSelect
  },
  data() {
    return {
      showJSONDialog: false,
      fullJSON: '',
      fullJsonLabel: '',
      showCorruptedMessage: false,
      snackbarText: '',
      snackShow: false,
      snackColor: 'success',
      err: '',
      errorsForSnackbar: [],
      showSnackErrors: false,
      success: '',
      testResult: '',
      resultColor: '',
      tab: 0,
      valid: false,
      valuesValid: false,
      isEdit: false,
      loading: true,
      lock: false,
      credential: {
        createdOn: '',
        id: 0,
        modifiedOn: '',
        name: '',
        status: '',
        type: this.$route.params.type ? this.$route.params.type.toUpperCase() : '',
        values: null,
        isGdprRelevant: false,
        isSystem: false,
        encryptedFields: null,
        adminOptions: {
          queryConsole: false
        }
      },
      script: '',
      allRoles: [],
      editRolesIds: [],
      useRolesIds: [],
      viewRolesIds: [],
      permissionsTypes: ['EDIT', 'USE', 'VIEW'],
      userRolesIds: [],
      userCanEdit: false,
      isSuperUser: false,
      executingQuery: false,
      executeResult: '',
      executeColor: '',
      showExecuteSuccessResult: false,
      executeSuccessResult: {
        affectedRows: 0,
        isSuccess: false,
        result: [
          {
            'additionalProp1': {},
            'additionalProp2': {},
            'additionalProp3': {}
          }
        ],
        error: ''
      },
      queryConsoleFromBe: false,
      dynamicTableHead: [],
      userCanDelete: false,
      triggerForceLogic: false,
      regularDeleteErrorsUsages: [],
      deleteSuccess: false,
      isCopy: false
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userSettings']),
    formatHint() {
      return `${this.$lang.labels.createdOn}: ${this.$options.filters.formatDateTime(this.credential.createdOn)}, ${this.$lang.labels.modifiedOn}: ${this.$options.filters.formatDateTime(this.credential.modifiedOn)}, ID: ${this.credential.id}`
    },
    useRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id))
    },
    viewRolePool() {
      return this.allRoles.filter((x) => !this.editRolesIds.includes(x.id) && !this.useRolesIds.includes(x.id))
    },
    isFormValid() {
      return this.valid && !this.lock && this.userCanEdit && this.valuesValid && this.credential.values
    }
  },
  watch: {
    isFormValid(val) {
      this.formValid(val)
    }
  },
  mounted() {
    bus.$on('saveResource', this.submit)
  },
  beforeDestroy() {
    bus.$off('saveResource', this.submit)
  },
  created() {
    this.isEdit = this.$route.name === 'credentialEdit'
    this.isCopy = !!this.$route.params.copy || !!this.$route.params.restore

    let user = null

    if (localStorage.userData) {
      user = JSON.parse(localStorage.userData)

      this.userRolesIds = user.roles.map((x) => x.id)
      this.isSuperUser = !!user.roles.find((x) => x.name === 'SUPER_USER')
      this.userCanDelete = !!user.roles.find((x) => x.name === 'CREDENTIAL_DELETER') || this.isSuperUser
    }

    this.init()
      .then(() => {
        if (this.isEdit && this.$route.params.id) {
          this.loading = true
          getCredential({ id: this.$route.params.id })
            .then((res) => {
              this.credential = res.data.data

              this.editRolesIds = this.credential.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
              this.useRolesIds = this.credential.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
              this.viewRolesIds = this.credential.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

              this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

              if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }

              if (res.data.data && res.data.data.adminOptions && res.data.data.adminOptions.queryConsole) {
                this.queryConsoleFromBe = true
              }

              this.fetchRequiredDefinitionCredentialRequest().forEach((x) => {
                if (this.credential.values[x] === null) {
                  this.showCorruptedMessage = true
                }
              })

              if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
                this.credential.encryptedFields.map((x) => {
                  x.isChanged = false
                  x.isEncrypt = x.type !== 'PLAIN'

                  return x
                })
                if (!this.credential.values) this.credential.values = {}
                let valuesKeys = []

                if (this.credential.values) valuesKeys = Object.keys(this.credential.values)
                this.credential.encryptedFields.forEach((x) => {
                  if (!valuesKeys.includes(x.field)) {
                    this.credential.values[x.field] = ''
                  }
                })
              }

              this.formatEncryptedFields()

              this.loading = false
            })
            .catch((err) => {
              this.addSnackbar({
                message: err,
                timeout: 5000,
                color: 'error'
              })            })
        } else if (this.$route.params.restore) {
          this.loading = true
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          this.credential = this.$route.params.restore
          delete this.credential.id
          delete this.credential.roles

          if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }

          if (this.credential.adminOptions && this.credential.adminOptions.queryConsole) {
            this.queryConsoleFromBe = true
          }

          this.fetchRequiredDefinitionCredentialRequest().forEach((x) => {
            if (this.credential.values[x] === null) {
              this.showCorruptedMessage = true
            }
          })

          if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
            this.credential.encryptedFields.map((x) => {
              x.isChanged = false
              x.isEncrypt = x.type !== 'PLAIN'

              return x
            })
            if (!this.credential.values) this.credential.values = {}
            let valuesKeys = []

            if (this.credential.values) valuesKeys = Object.keys(this.credential.values)
            this.credential.encryptedFields.forEach((x) => {
              if (!valuesKeys.includes(x.field)) {
                this.credential.values[x.field] = ''
              }
            })
          }

          this.formatEncryptedFields()

          this.loading = false

        } else {
          this.loading = true
          this.editRolesIds = user.roles.filter((x) => !x.isSystem).map((x) => x.id)
          this.userCanEdit = true
          if (this.$route.params.copy) {
            this.credential = this.$route.params.copy

            if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }

            if (this.credential.adminOptions && this.credential.adminOptions.queryConsole) {
              this.queryConsoleFromBe = true
            }

            this.fetchRequiredDefinitionCredentialRequest().forEach((x) => {
              if (this.credential.values[x] === null) {
                this.showCorruptedMessage = true
              }
            })

            if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
              this.credential.encryptedFields.map((x) => {
                x.isChanged = false
                x.isEncrypt = x.type !== 'PLAIN'

                return x
              })
              if (!this.credential.values) this.credential.values = {}
              let valuesKeys = []

              if (this.credential.values) valuesKeys = Object.keys(this.credential.values)
              this.credential.encryptedFields.forEach((x) => {
                if (!valuesKeys.includes(x.field)) {
                  this.credential.values[x.field] = ''
                }
              })
            }

            this.formatEncryptedFields()

            this.loading = false
          } else {
            this.formatEncryptedFields()
            this.loading = false
          }
        }
      })
  },
  methods: {
    ...mapActions('app', ['addSnackbar', 'formValid']),
    copyCreateResource,
    checkSftpValues,
    truncateString(str, length = 32) {
      if (str && JSON.stringify(str).length <= length) {
        return JSON.stringify(str)
      }

      return str ? JSON.stringify(str).substring(0, length) + '...' : ''
    },
    isJSON(value) {
      if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        return true
      }

      if (typeof value !== 'string') {
        return false
      }

      try {
        const parsed = JSON.parse(value)

        return typeof parsed === 'object' && parsed !== null && !Array.isArray(parsed)
      } catch (e) {
        return false
      }
    },
    openFullJSONDialog(jsonStr, jsonLabel) {
      this.fullJSON = jsonStr
      this.fullJsonLabel = jsonLabel
      this.showJSONDialog = true
    },
    fetchRequiredDefinitionCredentialRequest() {
      const typeString = this.credential.type.charAt(0).toUpperCase() + this.credential.type.slice(1).toLowerCase()
      let string = typeString + 'CredentialValues'

      if (string === 'Payment_sense_pacCredentialValues') {
        string = 'PaymentSensePacCredentialValues'
      } else if (string === 'Payment_sense_connect_eCredentialValues') {
        string = 'PaymentSenseConnectECredentialValues'
      }

      return definitions[string] ? definitions[string].required : []
    },
    deleteCredentialFunct(isForced = false) {
      this.triggerForceLogic = false
      this.regularDeleteErrorsUsages = []
      this.deleteSuccess = false
      deleteCredential({ id: this.credential.id, force: isForced })
        .then((res) => {
          if (res.status === 200) {
            this.deleteSuccess = true
            this.regularDeleteErrorsUsages = res.data.data.usages
          } else if (res.response.status === 400 && !res?.response?.data?.data?.usages) {
            this.addSnackbar({
              message: res.response.data.statusText,
              timeout: 5000,
              color: 'error'
            })
          } else {
            this.triggerForceLogic = true
            this.regularDeleteErrorsUsages = res.response.data.data.usages
          }
        })
        .catch((err) => {
          this.addSnackbar({
            message: err,
            timeout: 5000,
            color: 'error'
          })         })
    },
    copyField() {
      if (this.credential.name) {
        copy(this.credential.name)
        this.showSnack(this.$lang.success.copiedClipboard, 'success')
      } else {
        this.showSnack(this.$lang.errors.nothingToCopy, 'warning')
      }
    },
    showSnack(text, color = 'success') {
      this.snackbarText = text
      this.snackColor = color
      this.snackShow = true
    },
    resetExecuteJdbcScript() {
      this.executeResult = ''
      this.executeColor = ''
      this.executeSuccessResult = {
        affectedRows: 0,
        isSuccess: false,
        result: [],
        error: ''
      }
      this.showExecuteSuccessResult = false
    },
    async executeJdbcScriptFunc() {
      this.executeResult = ''
      this.executeColor = ''
      this.dynamicTableHead = []
      this.executingQuery = true

      try {

        const res = await executeJdbcScript({ id: this.credential.id, body: { script: this.script } })

        if (res && res.status !== 200) {

          this.executeColor = 'error'
          this.executeResult = this.$lang.errors.execute

          setTimeout(() => this.executeResult = '', 5000)
          this.executingQuery = false

          return
        }

        if (res && res.data && res.data.data && !res.data.data.isSuccess) {
          this.executeColor = 'error'
          this.executeResult = res ? res.data.data.error : this.$lang.errors.execute
          setTimeout(() => this.executeResult = '', res && res.data.data.error ? 20000 : 5000)
        }
        if (res && res.data && res.data.data) {
          this.$set(this, 'executeSuccessResult', res.data.data)
          if (res && res.data && res.data.data && res.data.data.isSuccess && res.data.data.result?.length) {
            const tableHeaderKeys = Object.keys(res.data.data.result[0])

            tableHeaderKeys.forEach((x) => {
              this.dynamicTableHead.push({ text: x, value: x,sortable: false })
            })
          }
          this.showExecuteSuccessResult = true
        }
        this.executingQuery = false

      } catch (err) {
        this.executeColor = 'error'
        this.executeResult = err
        this.executingQuery = false
        setTimeout(() => this.executeResult = '', 5000)
      }
    },
    formatEncryptedFields() {
      const typeFields = {
        JDBC: ['url', 'username', 'password'],
        JWT: ['privateKey'],
        MONGODB: ['url', 'clientKey'],
        SMTP: ['username', 'password', 'host', 'port', 'senderAddress'],
        TWILIO: ['sid', 'token'],
        SLACK: ['channelId', 'token'],
        FLOWY: ['username', 'apiKey'],
        REST: [],
        REST_BASIC: ['username', 'password'],
        REST_BEARER: ['token'],
        PLUGIN: [],
        AWS: ['accessKeyId', 'region', 's3Bucket', 'secretKey'],
        SECURITY: ['key', 'passphrase'],
        SCRIPT: [],
        IMAP: ['host', 'password', 'port', 'username'],
        KAFKA: ['bootstrapServers', 'groupId', 'topic'],
        PAYMENT_SENSE_PAC: ['apiKey', 'hostUrl', 'installerId', 'softwareHouseId', 'username'],
        JMS: ['password', 'destinationName', 'destinationType', 'uri', 'username'],
        PAYMENT_SENSE_CONNECT_E: ['hostUrl', 'jwt', 'merchantUrl'],
        RABBITMQ: ['exchange', 'host', 'password', 'port', 'routingKey', 'virtualHost', 'username'],
        MQTT: ['host', 'password', 'port', 'username', 'scheme', 'topic', 'clientId', 'rootCertificate', 'rootCertificatePassword', 'clientCertificate', 'clientPrivateKey'],
        FTP: ['host', 'password', 'port', 'username'],
        SFTP: ['host', 'password', 'port', 'username', 'privateKey', 'passphrase'],
        OPEN_AI: ['token'],
        LDAP: ['host', 'port', 'baseDn', 'userDn', 'password', 'certificate'],
        SSH: ['host', 'username', 'port', 'password'],
        SSH_KEY: ['host', 'username', 'port', 'password', 'privateKey', 'passphrase'],
        OUTLOOK_CALENDAR: ['clientId', 'clientSecret', 'tenant']
      }

      if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
        const existingFields = this.credential.encryptedFields.map((x) => x.field)

        if (this.credential.type === 'JWT') {
          existingFields.forEach((field, index) => {
            const splitField = field.split('.')

            if (splitField.length > 1) {
              existingFields[index] = splitField[splitField.length - 1]
              this.credential.encryptedFields[index].field = splitField[splitField.length - 1]
            }
          })
          if (!existingFields.includes('privateKey')) {
            this.credential.encryptedFields.push({
              field: 'privateKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            })
          }
        } else {
          typeFields[this.credential.type].forEach((field) => {
            if (!existingFields.includes(field)) {
              this.credential.encryptedFields.push({
                field: field,
                type: 'PLAIN',
                isEncrypt: false,
                isChanged: false
              })
            }
          })
        }
      } else {
        switch (this.credential.type) {
        case 'OUTLOOK_CALENDAR':
          this.credential.encryptedFields = [
            {
              field: 'clientId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'clientSecret',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'tenant',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SSH_KEY':
          this.credential.encryptedFields = [
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'privateKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'passphrase',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SSH':
          this.credential.encryptedFields = [
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'LDAP':
          this.credential.encryptedFields = [
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'baseDn',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'userDn',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'certificate',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'OPEN_AI':
          this.credential.encryptedFields = [
            {
              field: 'token',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SFTP':
          this.credential.encryptedFields = [
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'privateKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'passphrase',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'FTP':
          this.credential.encryptedFields = [
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'MQTT':
          this.credential.encryptedFields = [
            {
              field: 'scheme',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'topic',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'clientId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'rootCertificate',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'rootCertificatePassword',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'clientCertificate',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'clientPrivateKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'RABBITMQ':
          this.credential.encryptedFields = [
            {
              field: 'exchange',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'routingKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'virtualHost',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'PAYMENT_SENSE_CONNECT_E':
          this.credential.encryptedFields = [
            {
              field: 'hostUrl',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'jwt',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'merchantUrl',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'JMS':
          this.credential.encryptedFields = [
            {
              field: 'uri',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'destinationName',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'destinationType',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'PAYMENT_SENSE_PAC':
          this.credential.encryptedFields = [
            {
              field: 'apiKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'hostUrl',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'installerId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'softwareHouseId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'KAFKA':
          this.credential.encryptedFields = [
            {
              field: 'bootstrapServers',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'groupId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'topic',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SECURITY':
          this.credential.encryptedFields = [
            {
              field: 'key',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'passphrase',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'JWT':
          this.credential.encryptedFields = [
            {
              field: 'privateKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'MONGODB':
          this.credential.encryptedFields = [
            {
              field: 'url',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'clientKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'JDBC':
          this.credential.encryptedFields = [
            {
              field: 'url',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SMTP':
          this.credential.encryptedFields = [
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'senderAddress',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'TWILIO':
          this.credential.encryptedFields = [
            {
              field: 'sid',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'token',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SLACK':
          this.credential.encryptedFields = [
            {
              field: 'channelId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'token',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'FLOWY':
          this.credential.encryptedFields = [
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'apiKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'REST':
          this.credential.encryptedFields = []
          break
        case 'REST_BASIC':
          this.credential.encryptedFields = [
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'REST_BEARER':
          this.credential.encryptedFields = [
            {
              field: 'token',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'PLUGIN':
          this.credential.encryptedFields = []
          break
        case 'AWS':
          this.credential.encryptedFields = [
            {
              field: 'accessKeyId',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'region',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 's3Bucket',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'secretKey',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        case 'SCRIPT':
          this.credential.encryptedFields = []
          break
        case 'IMAP':
          this.credential.encryptedFields = [
            {
              field: 'host',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'password',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'port',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            },
            {
              field: 'username',
              type: 'PLAIN',
              isEncrypt: false,
              isChanged: false
            }
          ]
          break
        default:
        }
      }
    },
    async test() {
      this.testResult = ''
      this.resultColor = ''
      this.lock = true

      let res = null

      const values = { values: this.credential.values, encryptedFields: this.credential.encryptedFields }

      if (!this.isEdit) {
        values.encryptedFields = values.encryptedFields.map((x) => {
          x.type = 'PLAIN'

          return x
        })
      }

      try {
        switch (this.credential.type) {
        case 'OUTLOOK_CALENDAR':
          res = await testOutlookCalendar({ body: values })
          break
        case 'LDAP':
          res = await testLdap({ body: values })
          break
        case 'SSH':
          res = await testSsh({ body: values })
          break
        case 'SSH_KEY':
          res = await testSshKey({ body: values })
          break
        case 'OPEN_AI':
          res = await testOpenAi({ body: values })
          break
        case 'SFTP':
          res = await testSftp({ body: values })
          break
        case 'FTP':
          if (values.encryptedFields.find((x) => x.field === 'privateKey')) {
            values.encryptedFields = values.encryptedFields.filter((x) => x.field !== 'privateKey')
          }
          res = await testFtp({ body: values })
          break
        case 'MQTT':
          res = await testMqtt({ body: values })
          break
        case 'RABBITMQ':
          res = await testRabbitmq({ body: values })
          break
        case 'PAYMENT_SENSE_CONNECT_E':
          res = await testPaymentSenseConnectE({ body: values })
          break
        case 'JMS':
          res = await testJms({ body: values })
          break
        case 'PAYMENT_SENSE_PAC':
          res = await testPaymentSensePac({ body: values })
          break
        case 'KAFKA':
          res = await testKafka({ body: values })
          break
        case 'JWT':
          res = await testJwt({ body: values })
          break
        case 'MONGODB':
          res = await testMongoDb({ body: values })
          break
        case 'JDBC':
          res = await testJdbc({ body: values })
          break
        case 'SMTP':
          res = await testSmtp({ body: values })
          break
        case 'TWILIO':
          res = await testTwilio({ body: values })
          break
        case 'SLACK':
          res = await testSlack({ body: values })
          break
        case 'FLOWY':
          res = await testFlowy({ body: values })
          break
        case 'AWS':
          res = await testAws({ body: values })
          break
        case 'IMAP':
          res = await testImap({ body: values })
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {

          this.resultColor = 'error'
          this.testResult = this.$lang.errors.test

          setTimeout(() => this.testResult = '', 5000)
          this.lock = false

          return
        }

        if (res && res.data.data.isValid) {
          this.resultColor = 'success'
          this.testResult = this.$lang.success.test
          setTimeout(() => this.testResult = '', 5000)
        } else {
          this.resultColor = 'error'
          this.testResult = res ? res.data.data.error : this.$lang.errors.test
          setTimeout(() => this.testResult = '', res && res.data.data.error ? 20000 : 5000)
        }
        this.lock = false

      } catch (err) {
        this.resultColor = 'error'
        this.testResult = err
        this.lock = false
        setTimeout(() => this.testResult = '', 5000)
      }
    },
    init() {
      return new Promise((resolve) => {
        getRolesWithoutAuth()
          .then((roles) => {
            this.allRoles = roles
            resolve()
          })
          .catch((error) => {
            console.log(error)
            resolve()
          })
      })
    },
    afterSave(savedData) {
      this.credential = savedData

      this.editRolesIds = this.credential.roles.filter((x) => x.permissionType === 'EDIT').map((y) => y.role.id)
      this.useRolesIds = this.credential.roles.filter((x) => x.permissionType === 'USE').map((y) => y.role.id)
      this.viewRolesIds = this.credential.roles.filter((x) => x.permissionType === 'VIEW').map((y) => y.role.id)

      this.userCanEdit = this.isSuperUser ? true : !!this.editRolesIds.find((x) => this.userRolesIds.includes(x))

      if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }

      if (savedData && savedData.adminOptions && savedData.adminOptions.queryConsole) {
        this.queryConsoleFromBe = true
      }

      if (this.credential.encryptedFields && this.credential.encryptedFields.length > 0) {
        this.credential.encryptedFields.map((x) => {
          x.isChanged = false
          x.isEncrypt = x.type !== 'PLAIN'

          return x
        })
        if (!this.credential.values) this.credential.values = {}
        let valuesKeys = []

        if (this.credential.values) valuesKeys = Object.keys(this.credential.values)
        this.credential.encryptedFields.forEach((x) => {
          if (!valuesKeys.includes(x.field)) {
            this.credential.values[x.field] = ''
          }
        })
      }

      this.formatEncryptedFields()

      this.loading = false
    },
    async submit() {
      this.loading = true
      this.lock = true

      let res = null

      const localCredential = JSON.parse(JSON.stringify(this.credential))

      const { id } = localCredential

      if (!this.isEdit) {
        delete localCredential.id
        localCredential.status = 'ACTIVE'
      }
      delete localCredential.createdOn
      delete localCredential.modifiedOn

      const tempRoles = []

      this.editRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'EDIT', roleId: x })
      })

      this.useRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'USE', roleId: x })
      })

      this.viewRolesIds.forEach((x) => {
        tempRoles.push({ permissionType: 'VIEW', roleId: x })
      })

      localCredential.roles = tempRoles

      localCredential.encryptedFields.map((x) => {
        if (x.type !== 'PLAIN') {
          x.type = x.isChanged ? 'ENCRYPT' : 'ENCRYPTED'
        }

        if (!localCredential.values[x.field] && localCredential.type === 'JWT' && x.field !== 'privateKey') {
          x.field = `payload.${x.field}`
        }

        delete x.isChanged
        delete x.isEncrypt

        return x
      })

      if (localCredential.type === 'REST_BASIC') {
        if (!localCredential.values.username || !localCredential.values.password) {
          this.addSnackbar({
            message: this.$lang.errors.restBasic,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }
      }

      if (localCredential.type === 'REST_BARER') {
        if (!localCredential.values.token) {

          this.addSnackbar({
            message: this.$lang.errors.restBearer,
            timeout: 5000,
            color: 'error'
          })
          this.loading = false
          this.lock = false

          return
        }
      }

      if (localCredential.type === 'SFTP') {
        const canContinue = this.checkSftpValues(localCredential.values.username, localCredential.values.password, localCredential.values.privateKey, localCredential.values.passphrase)

        if (!canContinue) {
          this.addSnackbar({
            message: this.$lang.errors.wrongAuthCombination,
            timeout: 5000,
            color: 'error'
          })

          this.loading = false
          this.lock = false

          return
        }
      }

      try {
        switch (localCredential.type) {
        case 'OUTLOOK_CALENDAR':
          res = this.isEdit ? await updateOutlookCalendar({
            id,
            body: localCredential
          }) : await createOutlookCalendar({ body: localCredential })
          break
        case 'LDAP':
          res = this.isEdit ? await updateLdap({
            id,
            body: localCredential
          }) : await createLdap({ body: localCredential })
          break
        case 'SSH':
          res = this.isEdit ? await updateSsh({
            id,
            body: localCredential
          }) : await createSsh({ body: localCredential })
          break
        case 'SSH_KEY':
          res = this.isEdit ? await updateSshKey({
            id,
            body: localCredential
          }) : await createSshKey({ body: localCredential })
          break
        case 'OPEN_AI':
          res = this.isEdit ? await updateOpenAi({
            id,
            body: localCredential
          }) : await createOpenAi({ body: localCredential })
          break
        case 'SFTP':
          res = this.isEdit ? await updateSftp({
            id,
            body: localCredential
          }) : await createSftp({ body: localCredential })
          break
        case 'FTP':
          if (localCredential.encryptedFields.find((x) => x.field === 'privateKey')) {
            localCredential.encryptedFields = localCredential.encryptedFields.filter((x) => x.field !== 'privateKey')
            delete localCredential.values.privateKey
          }
          res = this.isEdit ? await updateFtp({
            id,
            body: localCredential
          }) : await createFtp({ body: localCredential })
          break
        case 'REST_BASIC':
          res = this.isEdit ? await updateRestBasic({
            id,
            body: localCredential
          }) : await createRestBasic({ body: localCredential })
          break
        case 'REST_BEARER':
          res = this.isEdit ? await updateRestBearer({
            id,
            body: localCredential
          }) : await createRestBearer({ body: localCredential })
          break
        case 'MQTT':
          res = this.isEdit ? await updateMqtt({
            id,
            body: localCredential
          }) : await createMqtt({ body: localCredential })
          break
        case 'RABBITMQ':
          res = this.isEdit ? await updateRabbitmq({
            id,
            body: localCredential
          }) : await createRabbitmq({ body: localCredential })
          break
        case 'PAYMENT_SENSE_CONNECT_E':
          res = this.isEdit ? await updatePaymentSenseConnectE({
            id,
            body: localCredential
          }) : await createPaymentSenseConnectE({ body: localCredential })
          break
        case 'JMS':
          res = this.isEdit ? await updateJms({
            id,
            body: localCredential
          }) : await createJms({ body: localCredential })
          break
        case 'PAYMENT_SENSE_PAC':
          res = this.isEdit ? await updatePaymentSensePac({
            id,
            body: localCredential
          }) : await createPaymentSensePac({ body: localCredential })
          break
        case 'KAFKA':
          res = this.isEdit ? await updateKafka({
            id,
            body: localCredential
          }) : await createKafka({ body: localCredential })
          break
        case 'SECURITY':
          res = this.isEdit ? await updateSecurity({
            id,
            body: localCredential
          }) : await createSecurity({ body: localCredential })
          break
        case 'JWT':
          res = this.isEdit ? await updateJwt({
            id,
            body: localCredential
          }) : await createJwt({ body: localCredential })
          break
        case 'MONGODB':
          res = this.isEdit ? await updateMongodb({
            id,
            body: localCredential
          }) : await createMongodb({ body: localCredential })
          break
        case 'JDBC':
          res = this.isEdit ? await updateJdbc({
            id,
            body: localCredential
          }) : await createJdbc({ body: localCredential })
          break
        case 'SMTP':
          res = this.isEdit ? await updateSmtp({
            id,
            body: localCredential
          }) : await createSmtp({ body: localCredential })
          break
        case 'TWILIO':
          res = this.isEdit ? await updateTwilio({
            id,
            body: localCredential
          }) : await createTwilio({ body: localCredential })
          break
        case 'SLACK':
          res = this.isEdit ? await updateSlack({
            id,
            body: localCredential
          }) : await createSlack({ body: localCredential })
          break
        case 'REST':
          res = this.isEdit ? await updateRest({
            id,
            body: localCredential
          }) : await createRest({ body: localCredential })
          break
        case 'FLOWY':
          res = this.isEdit ? await updateFlowy({
            id,
            body: localCredential
          }) : await createFlowy({ body: localCredential })
          break
        case 'PLUGIN':
          res = this.isEdit ? await updatePlugin({
            id,
            body: localCredential
          }) : await createPlugin({ body: localCredential })
          break
        case 'AWS':
          res = this.isEdit ? await updateAws({
            id,
            body: localCredential
          }) : await createAws({ body: localCredential })
          break
        case 'SCRIPT':
          res = this.isEdit ? await updateScript({
            id,
            body: localCredential
          }) : await createScript({ body: localCredential })
          break
        case 'IMAP':
          res = this.isEdit ? await updateImap({
            id,
            body: localCredential
          }) : await createImap({ body: localCredential })
          break
        default:
          res = null
        }

        if (res && res.status !== 200) {
          if (res?.response?.data?.data?.[0]?.error) {

            res?.response?.data?.data?.forEach((error) => {
              this.errorsForSnackbar.push({
                text: error.error,
                value: null
              })
            })
            this.showSnackErrors = true
          } else {
            const errorMessage = res?.response?.data?.statusText || (this.isEdit ? this.$lang.errors.credentialUpdate : this.$lang.errors.credentialCreate)

            this.addSnackbar({
              message: errorMessage,
              timeout: 5000,
              color: 'error'
            })
          }
          this.loading = false
          this.lock = false

          return
        }
        const successMessage = this.isEdit ? this.$lang.success.credentialUpdated : this.$lang.success.credentialCreated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })

        this.lock = false

        if (!this.isEdit && res) {
          this.credential = res.data.data
          if (!this.credential.adminOptions) this.credential.adminOptions = { queryConsole: false }
          this.isEdit = true
          this.$router.push({
            name: 'credentialEdit',
            params: {
              id: res.data.data.id
            }
          })
        } else {
          this.afterSave(res?.data?.data)
        }

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      }
    },
    async changeStatus() {
      this.loading = true
      this.lock = true
      try {
        const res = this.credential.status === 'ACTIVE' ? await disableCredential({ id: this.credential.id }) : await enableCredential({ id: this.credential.id })

        if (res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.credentialStatusUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.lock = false

          return
        }
        const successMessage = this.credential.status !== 'ACTIVE' ? this.$lang.success.credentialActivated : this.$lang.success.credentialDeactivated

        this.addSnackbar({
          message: successMessage,
          timeout: 5000,
          color: 'success'
        })
        this.credential.status = res.data.data.status

        this.loading = false
        this.lock = false

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.loading = false
        this.lock = false
      }
    }
  }
}
</script>
<style>
.full-width:nth-child(2) {
  min-width: 100%;
}

.v-icon.v-icon.v-icon--link{
  color: #009fb7;
}
</style>
